<script setup lang="ts">
import {PencilIcon} from "@heroicons/vue/24/outline"
import {ref} from "vue";
import EditHomePageDialog from "@/components/admin/EditHomePageDialog.vue";
import {useUserStore} from "@/stores/userStore";
import {storeToRefs} from "pinia";
import {useHomeStore} from "@/stores/homeStore";

const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);

const homeStore = useHomeStore();

const { homePageData } = storeToRefs(homeStore);

const isEditHomePageDialogOpen = ref(false)
const openEditHomePageDialog = () => {
  isEditHomePageDialogOpen.value = true
}

const closeEditHomePageDialog = () => {
  isEditHomePageDialogOpen.value = false
  homeStore.getHomePageData(true)
}
</script>

<template>
  <section class="flex flex-col md:flex-row gap-6 w-full px-12 md:px-24 py-12 relative">
    <button
        v-if="isAdmin"
        @click="openEditHomePageDialog"
        class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl absolute top-12 right-12">
      <PencilIcon class="h-4 w-4"/>
      <span class="ml-2">Edit</span>
    </button>
    <div class="flex flex-col w-full md:w-1/3 justify-center text-center md:text-left space-y-6">
      <h1 class="xl:text-[44px] md:text-[40px] text-[32px] font-bold">{{ homePageData?.title }}</h1>
      <p class="xl:text-[40px] md:text-[30px] text-[28px]">{{ homePageData?.description }}</p>
      <div class="">
        <router-link to="/pro" class="font-bold items-center text-black justify-center rounded-full bg-emerald-300 py-2 px-4 mt-4">Get Pro</router-link>
      </div>
    </div>
    <div class="flex flex-row w-full md:w-2/3 justify-center">
      <img :src="homePageData?.image" alt="Graph logo" class="w-full object-contain h-[320px] md:h-[520px]"/>
    </div>
    <div v-if="isEditHomePageDialogOpen">
      <EditHomePageDialog :on-close="closeEditHomePageDialog" :homeData="homePageData"  />
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
