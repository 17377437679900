<script setup lang="ts">
import { SunIcon, MoonIcon, FireIcon } from '@heroicons/vue/24/solid'
import {ref, watch} from "vue";
import LoginComponent from "@/components/LoginComponent.vue";
import {useUserStore} from "@/stores/userStore";
import {storeToRefs} from "pinia";
import ProfileIconComponent from "@/components/ProfileIconComponent.vue";

const isLoggedIn = ref(false)
const userStore = useUserStore();

const { userState, plan } = storeToRefs(userStore)
isLoggedIn.value = !!userState.value

let isPro = ref(false);

if(plan.value && plan.value?.expiry_date) {
  isPro.value = plan.value?.expiry_date.toDate() > new Date();
}

watch(plan, (newVal) => {
  isPro.value = newVal?.expiry_date.toDate() > new Date();
});


watch(userState, (newVal) => {
  isLoggedIn.value = !!newVal
});


const darkMode = ref(false)

const showLoginDialog = ref(false)

const toggleDarkMode = () => {
  darkMode.value = !darkMode.value
  document.body.classList.toggle('dark-theme')
  if (darkMode.value) {
    localStorage.setItem('theme', 'dark')
  } else {
    localStorage.setItem('theme', 'light')
  }
}

if (localStorage.getItem('theme') === 'dark') {
  darkMode.value = true
  document.body.classList.add('dark-theme')
} else {
  darkMode.value = false
  document.body.classList.remove('dark-theme')
}

const onOpenLoginDialog = () => {
  showLoginDialog.value = true
}

const onCloseLoginDialog = () => {
  showLoginDialog.value = false
}

</script>

<template>
  <nav class="flex h-[52px] text-[16px] flex-row justify-between px-4 md:px-12 py-3 bg-brand-card">
    <div class="flex flex-row space-x-2 md:space-x-2">
      <router-link to="/" class="h-6 object-contain">
        <picture class="h-6 object-contain">
          <source media="(min-width: 900px)" srcset="https://firebasestorage.googleapis.com/v0/b/coursemochu.appspot.com/o/logos%2Flogo.png?alt=media">
          <source media="(max-width: 480px)" srcset="https://firebasestorage.googleapis.com/v0/b/coursemochu.appspot.com/o/logos%2Flogo-mobile.png?alt=media">
          <img id="logo" class="h-6 object-contain" src="https://firebasestorage.googleapis.com/v0/b/coursemochu.appspot.com/o/logos%2Flogo.png?alt=media" alt="PT">
        </picture>
      </router-link>
      <router-link to="/courses"
                   :class="{
                      'font-bold bg-brand-card-hover': $route.path === '/courses',
                      'hover:bg-brand-card-hover': $route.path !== '/courses'
                   }"
                   class="font-bold items-center justify-center px-2 py-0.5 rounded-full">
        Courses
      </router-link>
    </div>
    <div class="flex flex-row space-x-2 md:space-x-3">
      <router-link to="/pro"
                   class="font-bold bg-emerald-300 text-black text-[16px] inline-flex items-center justify-center space-x-1 py-1 px-2 rounded-full">
        <FireIcon class="h-4 w-4" />
        <span>Pro</span>
      </router-link>
      <button class="hover:bg-slate-300 hover:text-black px-2 rounded-full" @click="toggleDarkMode">
        <SunIcon v-if="darkMode" class="h-6 w-6" />
        <MoonIcon v-else class="h-6 w-6" />
      </button>
      <ProfileIconComponent v-if="isLoggedIn" :user="userState" />
      <button id="login-button" v-if="!isLoggedIn" @click="onOpenLoginDialog"
              class="font-bold items-center justify-center py-0.5 rounded-full bg-brand-secondary px-3 text-white">Login</button>
    </div>
  </nav>
  <div v-if="showLoginDialog">
    <LoginComponent :on-close="onCloseLoginDialog"  />
  </div>
</template>

<style scoped lang="scss">

</style>
