<script setup lang="ts">

import {useHomeStore} from "@/stores/homeStore";
import {storeToRefs} from "pinia";
import { PhoneIcon, EnvelopeIcon, MapIcon } from "@heroicons/vue/24/solid"

const homeStore = useHomeStore();

const { homePageData } = storeToRefs(homeStore);


const getYear = () => new Date().getFullYear()

</script>

<template>
  <div class="flex flex-col bg-brand-card">
    <div class="w-full draggable">
      <div class="container flex flex-col mx-auto">
        <div class="flex flex-col items-center w-full my-8">
        <span class="mb-3">
          <img id="logo" alt="practicaltrading.in" src="https://firebasestorage.googleapis.com/v0/b/coursemochu.appspot.com/o/logos%2Flogo.png?alt=media" class="h-8"/>
        </span>
          <div class="flex flex-col items-center gap-2 mb-2">
            <div
                class="flex flex-wrap items-center justify-center gap-5 lg:gap-12 gap-y-3 lg:flex-nowrap text-brand-text opacity-50">
              <div class="flex flex-row items-center space-x-1">
                <PhoneIcon class="h-4 w-4" />
                <p>
                  Call us @ <a class="font-semibold" href="tel:+919003688975">+91 90036 88975</a>
                </p>
              </div>
              <div class="flex flex-row items-center space-x-1">
                <EnvelopeIcon class="h-4 w-4" />
                <p>
                  Write us @ <a class="font-semibold" href="mailto:support@practicaltrading.in">support@practicaltrading.in</a>
                </p>
              </div>
            </div>
            <div
                class="flex flex-wrap items-center justify-center gap-5 lg:gap-12 gap-y-3 lg:flex-nowrap text-brand-text opacity-50">
              <p>
                Reach us @ <a class="font-semibold">GROUND FLOOR SHOP 5,RATNA SHOPPING MALL, CHINTALAPUDI - 534460</a>
              </p>
            </div>
            <div
                class="flex flex-wrap items-center justify-center gap-5 lg:gap-12 gap-y-3 lg:flex-nowrap text-brand-text opacity-50">
              <router-link to="/pro" class="">Pro</router-link>
              <router-link to="/terms-of-service" class="">Terms of Service</router-link>
              <router-link to="/privacy-policy" class="">Privacy Policy</router-link>
            </div>
            <div class="flex items-center gap-4">
              <a target="_blank" :href="homePageData.instagram" class="text-grey-700 hover:text-grey-900">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.60063 2.18182H16.3991C19.3873 2.18182 21.8183 4.61281 21.8182 7.60074V16.3993C21.8182 19.3872 19.3873 21.8182 16.3991 21.8182H7.60063C4.6127 21.8182 2.18182 19.3873 2.18182 16.3993V7.60074C2.18182 4.61281 4.6127 2.18182 7.60063 2.18182ZM16.3993 20.0759C18.4266 20.0759 20.0761 18.4266 20.0761 16.3993H20.0759V7.60074C20.0759 5.57348 18.4265 3.92405 16.3991 3.92405H7.60063C5.57336 3.92405 3.92405 5.57348 3.92405 7.60074V16.3993C3.92405 18.4266 5.57336 20.0761 7.60063 20.0759H16.3993ZM6.85714 12.0001C6.85714 9.16424 9.16418 6.85714 12 6.85714C14.8358 6.85714 17.1429 9.16424 17.1429 12.0001C17.1429 14.8359 14.8358 17.1429 12 17.1429C9.16418 17.1429 6.85714 14.8359 6.85714 12.0001ZM8.62798 12C8.62798 13.8593 10.1407 15.3719 12 15.3719C13.8593 15.3719 15.372 13.8593 15.372 12C15.372 10.1406 13.8594 8.6279 12 8.6279C10.1406 8.6279 8.62798 10.1406 8.62798 12Z"
                        fill="currentColor"/>
                  <mask id="mask0_3320_6487" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2"
                        width="20" height="20">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.60063 2.18182H16.3991C19.3873 2.18182 21.8183 4.61281 21.8182 7.60074V16.3993C21.8182 19.3872 19.3873 21.8182 16.3991 21.8182H7.60063C4.6127 21.8182 2.18182 19.3873 2.18182 16.3993V7.60074C2.18182 4.61281 4.6127 2.18182 7.60063 2.18182ZM16.3993 20.0759C18.4266 20.0759 20.0761 18.4266 20.0761 16.3993H20.0759V7.60074C20.0759 5.57348 18.4265 3.92405 16.3991 3.92405H7.60063C5.57336 3.92405 3.92405 5.57348 3.92405 7.60074V16.3993C3.92405 18.4266 5.57336 20.0761 7.60063 20.0759H16.3993ZM6.85714 12.0001C6.85714 9.16424 9.16418 6.85714 12 6.85714C14.8358 6.85714 17.1429 9.16424 17.1429 12.0001C17.1429 14.8359 14.8358 17.1429 12 17.1429C9.16418 17.1429 6.85714 14.8359 6.85714 12.0001ZM8.62798 12C8.62798 13.8593 10.1407 15.3719 12 15.3719C13.8593 15.3719 15.372 13.8593 15.372 12C15.372 10.1406 13.8594 8.6279 12 8.6279C10.1406 8.6279 8.62798 10.1406 8.62798 12Z"
                          fill="white"/>
                  </mask>
                  <g mask="url(#mask0_3320_6487)">
                  </g>
                </svg>
              </a>
              <a target="_blank" :href="homePageData.youtube" class="text-grey-700 hover:text-grey-900">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="24" height="24">
                  <path fill="currentColor" d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>
              </a>
            </div>
          </div>
          <div class="flex items-center">
            <p class="text-base font-normal leading-7 text-center text-grey-700">
              PracticalTrading.in © {{ getYear() }} All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
