<script setup lang="ts">
import { useRoute } from 'vue-router'
import {useCoursesStore} from "@/stores/coursesStore";
import {computed, ref, watch} from "vue";
import {storeToRefs} from "pinia";
import {PlusIcon, PencilIcon} from "@heroicons/vue/24/outline";
import AddSectionDialog from "@/components/admin/AddSectionDialog.vue";
import AddLessonDialog from "@/components/admin/AddLessonDialog.vue";
import {useUserStore} from "@/stores/userStore";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {FireIcon} from "@heroicons/vue/24/solid";

const route = useRoute()
const coursesStore = useCoursesStore()
const userStore = useUserStore()


const slug = route.params.slug as string
const index = parseInt(route.params.id as string || "0")

const selectedVideo = ref<Record<string, any> | null>(null)

const { course, courses, loading } = storeToRefs(coursesStore)
const { userState, plan, isAdmin, completedLessons } = storeToRefs(userStore)

let isPro = ref(false);

if(plan.value && plan.value?.expiry_date) {
  isPro.value = plan.value?.expiry_date.toDate() > new Date();
}

watch(plan, (newVal) => {
  loading.value = false;
  isPro.value = newVal?.expiry_date.toDate() > new Date();
});

// if(course.value === null || course.value === undefined) {
//   setTimeout(() => {
//     coursesStore.fetchCourse(slug)
//   }, 1000);
// }
if (userState) {
  coursesStore.fetchCourse(slug)
}

watch(userState, (newVal) => {
  if(newVal) {
    coursesStore.fetchCourse(slug)
  }
  console.log(userState.value);
});

const setSelectedVideo = (video: Record<string, any>) => {
  selectedVideo.value = video
}

const setVideo = () => {
  if(course?.value === null || course?.value === undefined) return
  let count = 0
  for (let i = 0; i < course?.value?.sections.length; i++) {
    const lessonsLength = course?.value?.sections[i].lessons.length
    count += lessonsLength
    if(count > index) {
      try {
        setSelectedVideo(course?.value?.sections[i].lessons[lessonsLength - (count - index)])
      } catch (e) {
        console.error(e)
      }
      break
    }
  }
}

watch(course, () => {
  setVideo();
})
setVideo();

const lessonCompleted = computed(() => {
  if(!userState.value) return false;
  if(!selectedVideo.value) return false;
  return completedLessons.value.includes(selectedVideo.value?.slug)
})

const onChangeLessonCompleted = (e: any) => {
  if(!userState.value) return;
  if(!selectedVideo.value) return;
  if(e.target.checked) {
    userStore.updateUserLesson(selectedVideo.value?.slug, true)
  } else {
    userStore.updateUserLesson(selectedVideo.value?.slug, false)
  }
}

const isAddSectionDialogOpen = ref(false)
const editSection = ref(null)
const openAddSectionDialog = () => {
  isAddSectionDialogOpen.value = true
}
const openEditSectionDialog = (section: any) => {
  editSection.value = section
  isAddSectionDialogOpen.value = true
}
const closeAddSectionDialog = () => {
  isAddSectionDialogOpen.value = false
  editSection.value = null
  editLesson.value = null
  coursesStore.fetchCourse(course.value?.slug);
}

const isAddLessonDialogOpen = ref(false)
const editLesson = ref(null)
const openAddLessonDialog = () => {
  isAddLessonDialogOpen.value = true
}
const openEditLessonDialog = (lesson: any, section: any) => {
  editLesson.value = lesson
  editSection.value = section
  isAddLessonDialogOpen.value = true
}
const closeAddLessonDialog = () => {
  isAddLessonDialogOpen.value = false
  editSection.value = null
  editLesson.value = null
  coursesStore.fetchCourse(course.value?.slug, true);
}
</script>

<template>
  <div v-if="!loading" class="flex flex-col py-4 px-6">
    <div v-if="isAdmin" class="flex flex-row space-x-4 justify-end items-end px-12">
      <button
          @click="openAddSectionDialog"
          class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl">
        <PlusIcon class="h-4 w-4"/>
        <span class="ml-2">Add Section</span>
      </button>
      <button
          @click="openAddLessonDialog"
          class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl">
        <PlusIcon class="h-4 w-4"/>
        <span class="ml-2">Add Lesson</span>
      </button>
    </div>
    <div class="course-details relative flex flex-col-reverse md:flex-row py-6 gap-6 min-h-screen">
      <div v-if="course" class="w-full md:w-1/4 md:sticky md:top-6 flex flex-col bg-brand-card rounded-md px-2 py-4 h-full md:h-[calc(92vh)] overflow-hidden">
        <h1 class="text-[22px] font-semibold">{{ course?.title }}</h1>
        <div class="flex flex-1 flex-col space-y-4 h-full pt-6 overflow-y-scroll">
          <div v-for="(section, index) of course.sections" :key="'section-' + index" class="flex flex-col">
            <div class="flex flex-row pb-4 justify-between items-center">
              <h2 class="text-[20px] font-bold">{{ section.title }}</h2>
              <button
                  v-if="isAdmin"
                  @click="() => openEditSectionDialog(section)"
                  class="bg-brand-secondary text-white flex items-center p-2 rounded-full">
                <PencilIcon class="h-4 w-4"/>
              </button>
            </div>
            <div class="flex flex-col space-y-2">
              <div v-for="(lesson, index) of section.lessons" :key="'lesson-' + index"
                   @click="() => setSelectedVideo(lesson)"
                   :class="{
                    'bg-brand-card-hover': selectedVideo?.title === lesson.title,
                 }"
                   class="flex flex-row px-2 space-x-2 justify-between py-2 items-center hover:bg-brand-card-hover rounded-md cursor-pointer">
                <div class="flex flex-row items-center space-x-2">
                  <p class="text-[14px] font-semibold">{{ lesson.title }}</p>
                  <p v-if="lesson.type.toLowerCase() == 'free'" class="text-[12px] font-bold uppercase px-1 py-0.5 rounded-md bg-green-400 bg-opacity-50">{{ lesson.type }}</p>

                </div>
                <button
                    v-if="isAdmin"
                    @click="() => openEditLessonDialog(lesson, section)"
                    class="bg-brand-secondary text-white flex items-center p-2 rounded-full">
                  <PencilIcon class="h-4 w-4"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedVideo" class="w-full md:w-3/4 flex flex-col space-y-6">
        <h2 class="text-[22px] font-bold">{{ selectedVideo?.title }}</h2>
        <div v-if="!isPro && !selectedVideo.videoUrl && !isAdmin" class="mx-auto w-full px-6 lg:px-8 bg-black aspect-video flex flex-col items-center justify-center">
          <div class="mx-auto max-w-2xl sm:text-center flex flex-col space-y-6">
            <div class="flex flex-row items-center justify-center">
              <router-link to="/pro"
                           class="font-bold bg-emerald-300 text-black text-[22px] inline-flex items-center justify-center space-x-1 py-3 px-8 rounded-full">
                <FireIcon class="h-8 w-8" />
                <span>Pro</span>
              </router-link>
            </div>
          </div>
        </div>
        <iframe
            v-if="selectedVideo.videoUrl"
            :src="selectedVideo.videoUrl"
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            class="w-full aspect-video bg-black"></iframe>
        <div class="hidden sm:flex shadow bg-brand-card rounded-xl px-6 py-3">
          <div>
            <label class="inline-flex items-center">
              <input
                  class="w-3 h-3 sm:w-4 sm:h-4"
                  @change="onChangeLessonCompleted" type="checkbox" :checked="lessonCompleted">
              <span class="ml-2 text-[12px] font-medium md:text-[15px]">Mark Lesson Completed</span>
            </label>
          </div>
        </div>
        <div class="hidden sm:flex shadow-lg bg-brand-card rounded-xl text-[18px] px-6 py-6">
          <p v-if="selectedVideo?.description" class="font-medium">{{ selectedVideo?.description }}</p>
        </div>
      </div>
    </div>

    <div v-if="isAddSectionDialogOpen">
      <AddSectionDialog :on-close="closeAddSectionDialog" :section="editSection"  />
    </div>
    <div v-if="isAddLessonDialogOpen">
      <AddLessonDialog :on-close="closeAddLessonDialog" :lesson="editLesson" :section="editSection"  />
    </div>
  </div>
  <LoadingComponent v-if="loading" />
</template>

<style scoped lang="scss">

</style>
