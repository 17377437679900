<script setup lang="ts">
import axios from "axios";
import {useUserStore} from "@/stores/userStore";
import {storeToRefs} from "pinia";
import {ref, watch} from "vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import Api from "@/utils/constants";
const RAZORPAY_KEY = "rzp_test_XG95MHG9gJV1sx";

const userStore = useUserStore();

const { userState, plans, plan } = storeToRefs(userStore)

let loading = ref(false);
let startingPayment = ref(false);

let user = userState.value

let isPro = ref(false);

if(plan.value && plan.value?.expiry_date) {
  isPro.value = plan.value?.expiry_date.toDate() > new Date();
}

const pageTitle = ref("");

plans.value.forEach((plan) => {
  if (plan.pageTitle !== undefined && plan.pageTitle !== "") {
    pageTitle.value = plan.pageTitle;
  }
});

watch(plan, (newVal) => {
  loading.value = false;
  isPro.value = newVal?.expiry_date.toDate() > new Date();
});

watch(userState, (newVal) => {
  user = newVal;
});

const handlePaymentSuccess = async (response: any) => {
  if (response.error_code) {
    alert(`Payment failed: ${response.error_description || 'Unknown error'}`);
  } else {
    let storeKeys = Object.keys(localStorage).filter((key) => key.startsWith("course-") || key.startsWith("courseTimestamp-"));
    storeKeys.forEach((key) => {
      localStorage.removeItem(key);
    });

    loading.value = true;
    alert(`Payment successful!`);
    userStore.initListener();
    await userStore.fetchActivePlans();
  }
}

const onDoPayment = async (amount: number) => {
  if(!user) {
    document.getElementById("login-button")?.click();
    return;
  }
  const email = user?.email || user?.providerData[0]?.email || "";
  const name = user?.displayName || user?.providerData[0]?.displayName || "";
  const phone = user?.phoneNumber || user?.providerData[0]?.phoneNumber || "";
  const payload = {
    amount: amount * 100,
    currency: "INR",
  };
  try {
    startingPayment.value = true;
    const order = await axios.post(Api.CREATE_PAYMENT_ID, payload);
    const orderId = order.data.id;
    startingPayment.value = false;

    const customerDetails: any = {
      name: name,
      email: email,
      contact: phone,
    };

    const options = {
      key: RAZORPAY_KEY,
      order_id: orderId,
      amount: amount * 100,  // amount in the smallest currency unit
      currency: 'INR',
      name: 'PracticalTrading.in',
      description: 'PracticalTrading',
      image: 'https://firebasestorage.googleapis.com/v0/b/coursemochu.appspot.com/o/logos%2Flogo-mobile.png?alt=media',
      handler: handlePaymentSuccess,
      "notify": {
        "sms": true,
        "email": true
      },
      "notes": [user?.uid, "plan1"],
      prefill: customerDetails,
      user_id: user?.uid,
    };
    // eslint-disable-next-line no-undef
    const rzp = new Razorpay(options);
    rzp.open();
  } catch (e) {
    alert("Error while creating order");
  }
}

const memberExpiryDateFormat = () => {
  const date = plan.value?.expiry_date.toDate();
  return new Intl.DateTimeFormat('en-IN', { dateStyle: 'full', timeStyle: 'long' }).format(date);
}
</script>

<template>
  <div class="bg-brand py-12  md:py-24 sm:py-32">
    <template v-if="!loading">
      <div v-if="!isPro" class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto sm:text-center">
          <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">Simple no-tricks pricing</h2>
        </div>
        <div class="flex flex-col gap-12 md:flex-row justify-center mt-16">
          <div
              v-for="plan of plans"
              :key="plan.id"
              class="rounded-2xl bg-brand-card min-w-72 py-6 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-8">
            <h3 class="text-3xl font-bold tracking-tight  pb-4">{{ plan.title }}</h3>
            <div class="mx-auto max-w-xs px-8">
              <p class="mt-6 flex items-baseline justify-center gap-x-2">
                <span class="text-5xl font-bold tracking-tight">{{ plan.amount }}</span>
                <span class="text-sm font-semibold leading-6 tracking-wide opacity-50">INR</span>
              </p>
              <p class="text-sm opacity-50 pt-4">{{ plan.description }}</p>
              <LoadingComponent v-if="startingPayment" />
              <button
                  v-if="!startingPayment"
                  @click="onDoPayment(plan.amount)"
                 class="mt-10 block w-full rounded-md bg-brand-secondary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm">Get access</button>
            </div>
          </div>

        </div>
      </div>
      <div v-if="isPro" class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl sm:text-center flex flex-col space-y-6">
          <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">🎉 Congrats 🎉 You are a Pro User!</h2>
          <p class="text-xl font-bold tracking-tight opacity-50">Start watching courses</p>

          <p class="text-xl font-bold tracking-tight opacity-50">
            Your Pro membership expires on <strong class="opacity-100">{{ memberExpiryDateFormat() }}</strong>
          </p>
        </div>
      </div>
    </template>
    <LoadingComponent v-if="loading || plans.length === 0" />
  </div>
</template>

<style scoped lang="scss">

</style>
