<template>
  <div class="z-[9999]">
    <Menu as="div" class="relative block justify-center items-center h-full text-left">
      <div class="flex h-full items-center justify-center flex-col">
        <MenuButton
            class="inline-flex flex-col w-full h-full justify-center items-center"
        >

          <img v-if="image" :alt="name" class="rounded-full w-8 h-8" :src="image">
          <UserCircleIcon v-else class="rounded-full w-8 h-8"/>
        </MenuButton>
      </div>

      <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
            class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-brand-card shadow-lg ring-1 ring-black/5 focus:outline-none"
        >
          <div class="px-1 py-1">
            <div >
              <button
                  disabled
                  :class="[
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                ]"
              >

                {{ name }}
              </button>
            </div>

          </div>

          <div class="px-1 py-1">
            <MenuItem v-slot="{ active }">
              <button
                  @click="logout"
                  :class="[
                  active ? 'bg-red-500 text-white' : '',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                ]"
              >

                Logout
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { UserCircleIcon } from '@heroicons/vue/20/solid'
import {ref, toRefs, defineProps} from "vue";
import {
  signOut,
} from 'firebase/auth'
import {useFirebaseAuth} from "vuefire";

const auth = useFirebaseAuth()

const name = ref('')
const image = ref('')

const props = defineProps<{
  user: any
}>()


let { user } = toRefs(props)

if (user) {
  name.value = user.value.displayName || user.value.email || user.value.phoneNumber
  image.value = user.value.photoURL
}

const logout = async () => {
  await signOut(auth);
  window.location.reload()
}

</script>
